import { useContext, useState } from "react";
import Modal from "../utils/modal"
import AuthContext from "../../context/authProvider";
import ToastContext from "../../context/toastProvider";
import Button from "../atoms/button";
import { createGradableModel, getGradableModels } from "./functions";

const CreateGradableModelModal = ({ setRegister, refresh }) => {

    const { user } = useContext(AuthContext);
    const { goodToast, badToast } = useContext(ToastContext);

    const [game, setGame] = useState("");
    const [name, setName] = useState("");
    const [edition, setEdition] = useState("");
    const [year, setYear] = useState("");
    const [language, setLanguage] = useState("");
    const [numbering, setNumbering] = useState("");
    const [rarity, setRarity] = useState("");
    const [initial, setInitial] = useState("");

    const [copy, setCopy] = useState(false);
    const [model, setModel] = useState("");

    const handleClose = () => {
        setRegister(false);
    }

    const handleBody = () => {
        let body = {};
        if (game) {
            body.game = game;
        }
        if (name) {
            body.name = name;
        }
        if (edition) {
            body.edition = edition;
        }
        if (year) {
            body.year = year;
        }
        if (language) {
            body.language = language;
        }
        if (numbering) {
            body.numbering = numbering;
        }
        if (rarity) {
            body.rarity = rarity;
        }
        if (initial) {
            body.serialInitial = initial
        }

        return body;
    }

    const handleSubmit = async () => {
        const body = handleBody();
        const data = await createGradableModel(user.token, body);
        if (data.type === "success") {
            goodToast("Modelo avaliável cadastrado");
            handleClose();
            refresh();
        }
        if (data.type === "error") {
            badToast(data.message);
        }
    }

    const doFindModel = async () => {
        const modelsResponse = await getGradableModels(user.token, model, null, null, null);
        if (modelsResponse.type === "success") {
            const foundModel = modelsResponse.data[0];
            setGame(foundModel.game || "");
            setName(foundModel.name || "");
            setEdition(foundModel.edition || "");
            setYear(foundModel.year || "");
            setLanguage(foundModel.language || "");
            setNumbering(foundModel.numbering || "");
            setRarity(foundModel.rarity || "");
            setInitial(foundModel.serialInitial || "");
        }
    }

    return (
        <Modal handleClose={handleClose}>
            <h2 className="my-6 text-orange-500">NOVO MODELO AVALIÁVEL</h2>
            <div className="grid grid-cols-2 gap-2 w-fit m-auto">
                <label>Jogo: </label>
                <input type="text" value={game} onChange={e => setGame(e.target.value)} className="text-center text-black" />
                <label>Nome: </label>
                <input type="text" value={name} onChange={e => setName(e.target.value)} className="text-center text-black" />
                <label>Edição: </label>
                <input type="text" value={edition} onChange={e => setEdition(e.target.value)} className="text-center text-black" />
                <label>Ano: </label>
                <input type="text" value={year} onChange={e => setYear(e.target.value)} className="text-center text-black" />
                <label>Idioma: </label>
                <input type="text" value={language} onChange={e => setLanguage(e.target.value)} className="text-center text-black" />
                <label>Numeração: </label>
                <input type="text" value={numbering} onChange={e => setNumbering(e.target.value)} className="text-center text-black" />
                <label>Raridade: </label>
                <input type="text" value={rarity} onChange={e => setRarity(e.target.value)} className="text-center text-black" />
                <label>Inicial Serial: </label>
                <input type="text" value={initial} onChange={e => setInitial(e.target.value)} className="text-center text-black" />
            </div>

            <div>
                {
                    copy ?
                        <div className="mt-4 flex gap-4 m-auto justify-center">
                            <input className="px-2 text-black" type="text" value={model} onChange={e => setModel(e.target.value)} placeholder="ID Modelo" />
                            <button onClick={doFindModel} className="bg-blue-300 hover:bg-blue-400 text-black px-4">Buscar</button>
                            <button onClick={() => setCopy(false)} className="bg-red-500 hover:bg-red-800 text-black px-4">Cancelar</button>
                        </div>
                        :
                        <button onClick={() => setCopy(true)} className="bg-purple-400 hover:bg-purple-600 text-black mt-4 px-4">Copiar modelo</button>
                }
            </div>

            <div className="flex w-1/2 mx-auto my-4">
                <Button classes="bg-red-500 hover:bg-red-800 w-1/3" click={handleClose}>Cancelar</Button>
                <Button classes="bg-green-400 hover:bg-green-600 w-1/3" click={handleSubmit}>Cadastrar</Button>
            </div>
        </Modal>
    )
}

export default CreateGradableModelModal;
