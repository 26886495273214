import { useContext, useState } from "react";
import Modal from "../utils/modal"
import Button from "../atoms/button";
import Loading from "../atoms/loading";
import { addHoliday } from "./functions";
import AuthContext from "../../context/authProvider";
import ToastContext from "../../context/toastProvider";

const CreateHolidayModal = ({ setRegister, refresh }) => {

    const { user } = useContext(AuthContext);
    const { goodToast, badToast } = useContext(ToastContext);

    const [name, setName] = useState("");
    const [date, setDate] = useState("");

    const setFormattedDate = (value) => {
        value = value.replace(/\D/g, '');
        if(value.length > 2){
            value = value.substring(0, 2) + '/' + value.substring(2);
        }
        if(value.length > 5){
            value = value.substring(0, 5) + '/' + value.substring(5);
        }
        if(value.length <= 10){
            setDate(value);
        }
    }

    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setRegister(false);
    }

    const handleSubmit = async () => {
        if(name === ""){
            return badToast("Insira um nome");
        }
        if(date === ""){
            return badToast("Insira uma data");
        }

        const day = date.split("/")[0]
        const month = date.split("/")[1]-1
        const year = date.split("/")[2]
        const dateObj = new Date(year, month, day);
        
        setLoading(true);
        
        const createBody = {
            name,
            date: dateObj
        }

        const data = await addHoliday(user.token, createBody);
        if(data.type === "success"){
            setLoading(false);
            goodToast("Novo feriado cadastrada!");
            refresh();
            return handleClose();
        }
        setLoading(false);
        return badToast("Erro ao cadastrar feriado");
    }
    
    return (
        <Modal handleClose={handleClose}>
            <div className="my-4">
                <label>Nome: </label>
                <input type="text" value={name} onChange={e => setName(e.target.value)} className="text-black text-center mr-2 w-1/2"/>
            </div>
            <div className="my-4">
            <label>Data: </label>
                <input type="text" value={date} onChange={e => setFormattedDate(e.target.value)} className="text-black text-center"/>
           </div>
           <div className="flex w-1/2 m-auto mt-10">
                <Button classes="bg-red-500 hover:bg-red-800 w-1/3" click={handleClose}>Cancelar</Button>
                <Button classes="bg-green-400 hover:bg-green-600 w-1/3" click={handleSubmit}>Criar</Button>
            </div>
            {loading ? <Loading classes="m-auto my-2"/> : null}
        </Modal>
    )
}

export default CreateHolidayModal;
