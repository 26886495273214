import { Outlet, Link } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../context/authProvider";
import logo from "../../img/logo.svg";

const Layout = () => {

    const { user, logout } = useContext(AuthContext);
    //<span className="text-left m-auto p-1 text-xl"><h2>MGS Admin</h2></span>

    return (
        <div className="text-orange-100 flex flex-col min-h-screen">
            <header className="border-b border-[#d59106] pl-4">
                
                <img src={logo} alt="logo" className="w-20 my-4"/>
                <span className="text-center absolute top-3 right-3">
                    <p>{user.name}</p>
                    <p>{user.email}</p>
                    <Link onClick={logout} className="hover:text-[#d59106]">Sair</Link>
                </span>
            </header>
            <div className="flex-1 flex">
                <nav className="border-r border-[#b47c06] p-2 min-h-max w-32">
                    <ul className="text-center mt-4">
                        <li className="hover:text-[#d59106]"><Link to={"/manafix"}>Início</Link></li>
                        <li className="hover:text-[#d59106]"><Link to={"/manafix/users"}>Usuários</Link></li>
                        <li className="hover:text-[#d59106]"><Link to={"/manafix/products"}>Pedidos</Link></li>
                        <li className="hover:text-[#d59106]"><Link to={"/manafix/stores"}>Lojas</Link></li>
                        <li className="hover:text-[#d59106]"><Link to={"/manafix/transactions"}>Transações</Link></li>
                        <li className="hover:text-[#d59106]"><Link to={"/manafix/gradable-models"}>Modelos</Link></li>
                        <li className="hover:text-[#d59106]"><Link to={"/manafix/serials"}>Seriais</Link></li>
                        <li className="hover:text-[#d59106]"><Link to={"/manafix/coupons"}>Cupons</Link></li>
                        <li className="hover:text-[#d59106]"><Link to={"/manafix/feriados"}>Feriados</Link></li>
                        {
                            user.roles.includes("master") ? <li className="hover:text-[#d59106]"><Link to={"/manafix/logs"}>Logs</Link></li>
                            : null
                        }
                        <li className="hover:text-[#d59106]"><Link onClick={logout}>Sair</Link></li>
                    </ul>
                </nav>
                <div className="flex-1"><Outlet/></div>
            </div>
            <footer className="text-center">v 1.1</footer>
        </div>
    )
}

export default Layout;