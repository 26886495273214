import Button from "../atoms/button";
import { deleteHoliday } from "./functions";
import { useContext } from "react";
import AuthContext from "../../context/authProvider";
import { formatarData } from "../utils/functions";

const HolidaysList = ({ holidays, refresh }) => {

    const { user } = useContext(AuthContext);

    const handleDelete = async (holiday) => {
        const data = await deleteHoliday(user.token, holiday._id);
        if(data.type === "success"){
            refresh();
        }
    }

    return (
        <table className="text-center m-auto gap-5">
            <thead>
                <tr>
                    <th className="p-4">Nome</th>
                    <th className="p-4">Data</th>
                    <th className="p-4"></th>
                </tr>
            </thead>
            <tbody>
                {
                    holidays.map((holiday, idx) => (
                        <tr key={idx}>
                            <td className="p-4">{holiday.name}</td>
                            <td className="p-4">{formatarData(holiday.date)}</td>
                            <td className="p-4"><Button classes="bg-blue-500 hover:bg-blue-800" click={() => handleDelete(holiday)}>Excluir</Button></td>
                        </tr>
                        
                    ))
                }
            </tbody>
        </table>
    )
}

export default HolidaysList;
