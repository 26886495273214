import { useContext, useState } from "react";
import { updateProductPriceDeadlineComment } from "../functions";
import ToastContext from "../../../context/toastProvider";
import Button from "../../atoms/button";
import AuthContext from "../../../context/authProvider";

const ProductAlter = ({ editableProduct, handleClose, refresh }) => {

    const { goodToast, badToast } = useContext(ToastContext);
    const { user } = useContext(AuthContext);

    const [finalPrice, setFinalPrice] = useState(editableProduct.finalPrice || "");
    const [deadline, setDeadline] = useState(editableProduct.deadline || "");

    const [alterPrice, setAlterPrice] = useState(false);
    const [alterDeadline, setAlterDeadline] = useState(false);

    const [alterComment, setAlterComment] = useState(false);
    const [comment, setComment] = useState(editableProduct.comment || "");

    const cancelPrice = () => {
        setFinalPrice(editableProduct.finalPrice);
        setAlterPrice(false);
    }

    const cancelDeadline = () => {
        setDeadline(editableProduct.deadline);
        setAlterDeadline(false);
    }

    const cancelComment = () => {
        setComment(editableProduct.comment || "");
        setAlterComment(false);
    }

    const handleSubmit = async () => {
        const data = await updateProductPriceDeadlineComment(user.token, editableProduct._id, finalPrice, deadline, comment);
        if(data.type === "success"){
            goodToast("Dados do produto atualizados!");
            handleClose();
            refresh();
        }
        if(data.type === "error"){
            badToast(data.message);
        }
    }

    return(
        <div className="min-h-[300px] px-10">
            <div className="flex mb-10">
                <label className="m-auto">ID: </label>
                <input type="text" value={editableProduct._id} className="text-black text-center m-2 w-full" disabled/>
            </div>
            {/* <button onClick={() => console.log(editableProduct)}>depurate</button> */}
            {
                alterPrice ?
                <p className="text-red-500 mb-4">ATENÇÃO! O valor do pedido não deve conter separador decimal ("." ou ","), deve ser um número inteiro 100x maior do que o valor. <br/> Ex: R$10,00 será 1000</p>
                : null
            }
            <div className="flex gap-4 justify-center w-full">
                <p className="w-32">Preço final:</p>
                <input className="text-center text-black flex-1" disabled={!alterPrice} type="text" value={finalPrice} onChange={e => setFinalPrice(e.target.value)}/>
                {
                    alterPrice ?
                    <button className="bg-red-500 hover:bg-red-800 text-black px-2" onClick={cancelPrice}>Cancelar</button>
                    :
                    <button className="bg-green-400 hover:bg-green-600 text-black px-2" onClick={() => setAlterPrice(true)}>Alterar</button>
                }
            </div>
            <div className="flex gap-4 justify-center mt-4 w-full">
                <p className="w-32">Prazo:</p>
                <input className="text-center text-black flex-1" disabled={!alterDeadline} type="text" value={deadline} onChange={e => setDeadline(e.target.value)}/>
                {
                    alterDeadline ?
                    <button className="bg-red-500 hover:bg-red-800 text-black px-2" onClick={cancelDeadline}>Cancelar</button>
                    :
                    <button className="bg-green-400 hover:bg-green-600 text-black px-2" onClick={() => setAlterDeadline(true)}>Alterar</button>
                }
            </div>
            <div className="flex gap-4 justify-center mt-4 w-full">
                <p className="w-32">Comentário:</p>
                <textarea className="text-center text-black flex-1" disabled={!alterComment} type="text" value={comment} onChange={e => setComment(e.target.value)}/>
                {
                    alterComment ?
                    <button className="bg-red-500 hover:bg-red-800 text-black px-2" onClick={cancelComment}>Cancelar</button>
                    :
                    <button className="bg-green-400 hover:bg-green-600 text-black px-2" onClick={() => setAlterComment(true)}>Alterar</button>
                }
            </div>

            <div className="flex w-1/2 mx-auto my-4">
                <Button classes="bg-red-500 hover:bg-red-800 w-1/3" click={handleClose}>Cancelar</Button>
                <Button disabled={finalPrice===editableProduct.finalPrice && deadline === editableProduct.deadline && (comment === editableProduct.comment || (!comment && !editableProduct.comment))} classes="bg-green-400 hover:bg-green-600 w-1/3" click={handleSubmit}>Atualizar</Button>
            </div>

        </div>
    )
}

export default ProductAlter;