import { GRADABLE_MODELS_URL, SERIALS_URL } from "../../context/paths";

export const getGradableModels = async (token, id, game, name, numbering) => {
    const searchOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        }
    }

    let queryUrl = GRADABLE_MODELS_URL;
    const params = new URLSearchParams();
    if(id){
        params.append("id", id);
    }
    if(game){
        params.append("game", game);
    }
    if(name){
        params.append("name", name);
    }
    if(numbering){
        params.append("numbering", numbering);
    }
    if(params){
        queryUrl += "?"+params.toString();
    }

    try{
        const response = await fetch(queryUrl, searchOptions);
        return await response.json();
    }catch(error){
        return {
            message: error.message,
            type: "error"
        }
    }
}

export const createGradableModel = async (token, body) => {
    const createOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }

    const CREATE_GM_URL = `${GRADABLE_MODELS_URL}/add`;

    try{
        const response = await fetch(CREATE_GM_URL, createOptions);
        return await response.json();
    }catch(error){
        return {
            message: error.message,
            type: "error"
        }
    }
}

export const updateGradableModel = async (token, id, body) => {
    const updateOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }

    const UPDATE_GM_URL = `${GRADABLE_MODELS_URL}/update/${id}`;

    try{
        const response = await fetch(UPDATE_GM_URL, updateOptions);
        return await response.json();
    }catch(error){
        return {
            message: error.message,
            type: "error"
        }
    }
}

export const getSerialsInfos = async (token, id) => {
    const getOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        }
    }

    const GET_SERIALS_URL = `${GRADABLE_MODELS_URL}/serials?id=${id}`;
    try{
        const response = await fetch(GET_SERIALS_URL, getOptions);
        return await response.json();
    }catch(error){
        return {
            message: error.message,
            type: "error"
        }
    }
}

export const generateSerial = async (token, initial) => {
    try{
        const generateOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify({initial})
        }
        const GENERATE_SERIAL_URL = `${SERIALS_URL}/generate`;
        const response = await fetch(GENERATE_SERIAL_URL, generateOptions);
        return await response.json();

    }catch(error){
        return {
            message: error.message,
            type: "error"
        }
    }

}   

export const deleteModel = async (token, modelId) => {
    try{
        const deleteOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            }
        }
        const DELETE_MODEL_URL = `${GRADABLE_MODELS_URL}/delete/${modelId}`;
        const response = await fetch(DELETE_MODEL_URL, deleteOptions);
        return await response.json();

    }catch(error){
        return {
            message: error.message,
            type: "error"
        }
    }

}   
