const Modal = ({ handleClose, children }) => {

    return (
        <div className="w-screen overflow-y-auto h-screen fixed top-0 left-0 bg-[rgb(0,0,0,0.7)]" onClick={handleClose}>
            <div className="w-1/2 h-fit overflow-y-auto m-auto mt-4 p-2 bg-black border border-[#d59106]" onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    )
}

export default Modal;