import { PRODUCTS_URL, PARTNER_PRODUCTS_URL, ACTIVE_STORES_URL } from "../../context/paths";
import { partnerStatusList, statusList } from "../utils/allProductStatus";

export const getProducts = async (token, email, id, status, delivery, store) => {
    const searchOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        }
    }

    let queryUrl = PRODUCTS_URL;
    const params = new URLSearchParams();
    if(email){
        params.append("user_email", email);
    }
    if(id){
        params.append("id", id);
    }
    if(status){
        params.append("status", status);
    }
    if(delivery){
        params.append("delivery", delivery);
    }
    if(store){
        params.append("store", store);
    }
    if(params){
        queryUrl += "?"+params.toString();
    }

    try{
        const response = await fetch(queryUrl, searchOptions);
        return await response.json();
    }catch(error){
        return {
            type: "error",
            message: error
        }
    }
    
}

export const updateProductStatus = async(token, productId, status, notify, statusMessage, returnCode="") => {
    if(!statusList.includes(status)){
        return {
            message: "Status inválido",
            type: "error"
        }
    }
    const updateOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        },
        body: JSON.stringify({status, notify, statusMessage, returnCode})
    }

    const UPDATE_PRODUCT_URL = `${PRODUCTS_URL}/status/${productId}`;
    try{
        const response = await fetch(UPDATE_PRODUCT_URL, updateOptions);
        return await response.json();
    }catch(error){
        return {
            message: error.message,
            type: "error"
        }
    }
}

export const updateProductPriceDeadlineComment = async(token, productId, finalPrice, deadline, comment) => {
    const updateOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        },
        body: JSON.stringify({finalPrice, deadline, comment})
    }

    const UPDATE_PRODUCT_URL = `${PRODUCTS_URL}/update/${productId}`;
    try{
        const response = await fetch(UPDATE_PRODUCT_URL, updateOptions);
        return await response.json();
    }catch(error){
        return {
            message: error.message,
            type: "error"
        }
    }
}

export const getPartnerProducts = async (token, email, id, status) => {

    const searchOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        }
    }

    let queryUrl = PARTNER_PRODUCTS_URL;
    const params = new URLSearchParams();
    if(email){
        params.append("user_email", email);
    }
    if(id){
        params.append("id", id);
    }
    if(status){
        params.append("status", status);
    }
    if(params){
        queryUrl += "?"+params.toString();
    }
    try{
        const response = await fetch(queryUrl, searchOptions, updateProductStatus);
        return await response.json();
    }
    catch(error){
        return {
            message: error.message,
            type: "error"
        }
    }
    
}

export const updatePartnerProductStatus = async(token, productId, status, notify, statusMessage) => {
    if(!partnerStatusList.includes(status)){
        return {
            message: "Status inválido",
            type: "error"
        }
    }
    const updateOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        },
        body: JSON.stringify({status, notify, statusMessage})
    }

    const UPDATE_PRODUCT_URL = `${PRODUCTS_URL}/partner/status/${productId}`;
    try{
        const response = await fetch(UPDATE_PRODUCT_URL, updateOptions);
        return await response.json();
    }
    catch(error){
        return {
            message: error.message,
            type: "error"
        }
    }
}

export const getSpecialPhrase = async (token, productId) => {
    try{
        const getOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            }
        }
    
        const SPECIAL_PHRASE_URL = `${PRODUCTS_URL}/phrase/${productId}`;
        const response = await fetch(SPECIAL_PHRASE_URL, getOptions);
        return await response.json();
    }catch(error){
        return {
            message: "Something went wrong",
            type: "error",
            error: error.message
        }
    }
}

export const getSpecialPhrasePartner = async (token, productId) => {
    try{
        const getOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            }
        }
    
        const SPECIAL_PHRASE_URL = `${PRODUCTS_URL}/partner/phrase/${productId}`;
        const response = await fetch(SPECIAL_PHRASE_URL, getOptions);
        return await response.json();
    }catch(error){
        return {
            message: "Something went wrong",
            type: "error",
            error: error.message
        }
    }
}

export const addProduct = async (token, body, partner) => {
    try{
        const addOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify(body)
        }

        const ADD_PRODUCTS_URL = partner ? PARTNER_PRODUCTS_URL+"/add" : PRODUCTS_URL+"/add";

        const response = await fetch(ADD_PRODUCTS_URL, addOptions);
        return await response.json();

    }catch(error){
        return {
            message: "Something went wrong",
            type: "error",
            error: error.message
        }
    }
}

export const getActiveStores = async () => {
    try{
        const getOptions = {
            method: "GET"
        }

        const response = await fetch(ACTIVE_STORES_URL, getOptions);
        return await response.json();
    }catch(error){
        return {
            message: "Something went wrong",
            type: "error",
            error: error.message
        }
    }
}
