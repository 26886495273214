import { HOLIDAYS_URL } from "../../context/paths";

export const getHolidays = async (token) => {
    const searchOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        }
    }

    try{
        const response = await fetch(HOLIDAYS_URL, searchOptions);
        return await response.json();
    }catch(error){
        return {
            message: error.message,
            type: "error"
        }
    }
    
}

export const addHoliday = async (token, body) => {
    const addOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }

    const addURL = `${HOLIDAYS_URL}/`;

    try{
        const response = await fetch(addURL, addOptions);
        return await response.json();
    }catch(error){
        return {
            message: error.message,
            type: "error"
        }
    }
}

export const deleteHoliday = async (token, id) => {
    const deleteOptions = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        }
    }

    const deleteURL = `${HOLIDAYS_URL}/${id}`;

    try{
        const response = await fetch(deleteURL, deleteOptions);
        return await response.json();
    }catch(error){
        return {
            message: error.message,
            type: "error"
        }
    }
}

