import { cashnator, formatarData } from "../../utils/functions";

const ProductInfos = ({ editableProduct }) => {

    const paidAt = editableProduct.paidAt ? formatarData(new Date(editableProduct.paidAt)) : "-";
    const holdAt = editableProduct.holdAt ? formatarData(new Date(editableProduct.holdAt)) : "-";
    const receivedAt = editableProduct.receivedAt ? formatarData(new Date(editableProduct.receivedAt)) : "-";
    const gradedAt = editableProduct.gradedAt ? formatarData(new Date(editableProduct.gradedAt)) : "-";

    return (
        <div>
            <div className="flex">
                <label className="m-auto">ID: </label>
                <input type="text" value={editableProduct._id} className="text-black text-center m-2 w-full" disabled />
            </div>
            {/* <button onClick={() => console.log(editableProduct)}>depurate</button> */}
            <p className="my-4">Usuário: {editableProduct?.user?.name}</p>
            <p className="my-4">Email: {editableProduct?.user?.email}</p>
            <p className="my-4">Tipo da avaliação: MANAFIX {editableProduct?.gradingType === "pre" ? "PRÉ GRADING" : editableProduct?.gradingType?.toUpperCase()}</p>
            <p className="my-4">Nº Cartas: {editableProduct?.numberOfCards}</p>
            <p className="my-4">Prioridade: {editableProduct?.priority}</p>
            <p className="my-4">Prazo: {editableProduct?.deadline} dias</p>
            <p className="my-4">Entrega: {editableProduct?.delivery}</p>
            {
                editableProduct?.delivery === "correios" ?
                    <div className="w-fit m-auto border border-[#777] px-4">
                        <p className="text-center border-b border-[#777] py-2 mb-2">Endereço</p>
                        {
                            editableProduct?.postalCode ? 
                            <div>
                        <p className="text-left">CEP: <strong className="text-[#d59106]">{editableProduct?.postalCode}</strong></p>
                        <p className="text-left">Rua: <strong className="text-[#d59106]">{editableProduct?.street}</strong></p>
                        <p className="text-left">Bairro: <strong className="text-[#d59106]">{editableProduct?.district}</strong></p>
                        <p className="text-left">Cidade: <strong className="text-[#d59106]">{editableProduct?.city}</strong></p>
                        <p className="text-left">Estado: <strong className="text-[#d59106]">{editableProduct?.state}</strong></p>
                        <p className="text-left">País: <strong className="text-[#d59106]">{editableProduct?.country}</strong></p>
                        <p className="text-left">Número: <strong className="text-[#d59106]">{editableProduct?.houseNumber}</strong></p>
                        <p className="text-left">Complemento: <strong className="text-[#d59106]">{editableProduct?.complement}</strong></p>
                        </div>
                        : <p className="pb-2 text-[#d59106]">Utilizar o endereço do usuário.</p>
                        }
                    </div>
                    : null
            }
            <p className="my-4">Preço final: {cashnator(editableProduct?.finalPrice)}</p>
            <p className="my-4">Preço seguro: {cashnator(editableProduct?.insurance || 0)}</p>
            <p className="my-4">Preço frete: {cashnator(editableProduct?.shipping || 0)}</p>
            {
                editableProduct?.delivery === "correios" ?
                    <p className="my-4">Código de rastreio: {editableProduct?.trackingCode || "não informado"}</p>
                    : null
            }
            {
                editableProduct.delivery === "presencial" ?
                    <p className="my-4">Loja: {editableProduct.store?.name}</p>
                    : null
            }
            <div className="">
                <div className="w-1/2 flex m-auto">Pago: <span className="text-right flex-1">{paidAt}</span></div>
                {
                    editableProduct.delivery === "presencial" && editableProduct.store._id.toString() !== "651ea71ab7f1091f37f9230d" ?
                        <div className="w-1/2 flex m-auto">Recebido pelo parceiro: <span className="text-right flex-1">{holdAt}</span></div>
                        : null
                }

                <div className="w-1/2 flex m-auto">Recebido: <span className="text-right flex-1">{receivedAt}</span></div>
                <div className="w-1/2 flex m-auto">Avaliado: <span className="text-right flex-1">{gradedAt}</span></div>
            </div>

        </div>
    )
}

export default ProductInfos;