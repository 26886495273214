import { useContext, useState } from "react";
import Loading from "../atoms/loading";
import HolidaysList from "./holidaysList";
import ToastContext from "../../context/toastProvider";
import Button from "../atoms/button";
import { getHolidays } from "./functions";
import CreateHolidayModal from "./createHolidayModal";

const Holiday = ({ user }) => {

    const { badToast } = useContext(ToastContext);

    const [holidays, setHolidays] = useState([]);
    const [loading, setLoading] = useState(false);
    const [register, setRegister] = useState(false);

    const searchHolidays = async () => {
        const data = await getHolidays(user.token);
        setHolidays(data);
        setLoading(false);
        if(data.type === "error"){
            badToast(data.message);
        }
    }

    const handleSearch = () => {
        setLoading(true)
        searchHolidays();
    }

    return (
        <main className="text-center">
            <h1>Feriados</h1>
            <div className="border-b border-t border-[#d59106] p-2">
                <Button click={handleSearch} classes="bg-[#d59106] hover:bg-[#b47c06] inline">Pesquisar Feriados</Button>
                <Button classes="bg-blue-300 hover:bg-blue-400 inline ml-10" click={() => setRegister(true)}>Cadastrar Feriado</Button>
            </div>
            {
                loading ? <Loading classes="m-auto"/>
                : holidays?.type === "success" ?
                <HolidaysList holidays={holidays.data} refresh={handleSearch}/>
                : holidays?.type === "error" ?
                <div>
                    <p className="text-center m-8 text-red-600">{holidays.message}</p>
                </div>
                : null
            }
            {
                register ? <CreateHolidayModal setRegister={setRegister} refresh={handleSearch}/> : null
            }
        </main>
    )
}

export default Holiday;